.fullWidthButton {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	padding: 0 4px;
	margin: 4px 0 0;
	height: 26px;
	width: 100%;

	font-size: 12px;
	border: 1px solid #f9fafa;
	border-radius: 2px;
	background: #f9fafa;
}

.fullWidthButton:hover {
	background: rgb(0, 0, 0, 0.15);
	border: 1px solid rgb(0, 0, 0, 0.15);
}

.noOutlineText {
	font-size: 12px;
}
