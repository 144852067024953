.collapseIcon {
  margin-top: 5px;
  margin-right: 2px;
}

.nestable-item-handler {
  height: 26px;
  position: absolute;
  left: 0;
  right: 0;
}

.nestable-item--with-children > div .nestable-item-handler {
  left: 20px;
}
