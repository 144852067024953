.sortableItem {
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
	padding: 0 4px;
	font-size: 12px;
	border: 1px solid #f9fafa;
	background: #f9fafa;
	height: 26px;
}

.sortableItem:hover {
	background: rgb(0, 0, 0, 0.15);
	border: 1px solid rgb(0, 0, 0, 0.15);
}

.sortableItem > .label {
	flex: 1;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.sortableItem .controls {
	padding-left: 4px;
	display: flex;
	flex-direction: column;
	color: #666;
	visibility: hidden;
	z-index: 1;
}

.sortableItem .metadata {
	position: absolute;
	right: 4px;
	color: #666;
}

.sortableItem .metadata span {
	font-weight: bold;
}

.sortableItem svg {
	cursor: pointer;
}

.sortableItem:hover .controls {
	visibility: visible;
}

.sortableItem:hover .metadata {
	visibility: hidden;
}

.sortableItem .inputWrapper {
	display: flex;
	flex-direction: row;
	align-content: stretch;
}

.inputWrapper input {
	flex: 1;
	width: 100%;
}

/* Overwrites */
.nestable-item, .nestable-item-copy, .nestable-item .nestable-list, .nestable-item-copy .nestable-list {
	margin: 4px 0 0 !important;
}
