#outlineManagerView {
  z-index: 1000;
  background: #d5d5d8;
  position: absolute;
  inset: 0;
}

.react-contexify {
  opacity: 0;
  -webkit-user-select: none;
  user-select: none;
  box-sizing: border-box;
  min-width: 200px;
  z-index: 100;
  background-color: #fff;
  border-radius: 6px;
  padding: 6px 0;
  position: fixed;
  box-shadow: 0 10px 30px -5px #0000004d;
}

.react-contexify__submenu--is-open, .react-contexify__submenu--is-open > .react-contexify__item__content {
  color: #fff;
  background-color: #4393e6;
}

.react-contexify__submenu--is-open > .react-contexify__submenu {
  pointer-events: initial;
  opacity: 1;
}

.react-contexify .react-contexify__submenu {
  pointer-events: none;
  transition: opacity .275s;
  position: absolute;
  top: -6px;
}

.react-contexify__submenu-arrow {
  margin-left: auto;
  font-size: 12px;
}

.react-contexify__separator {
  width: 100%;
  height: 1px;
  cursor: default;
  background-color: #0003;
  margin: 4px 0;
}

.react-contexify__will-leave--disabled {
  pointer-events: none;
}

.react-contexify__item {
  cursor: pointer;
  position: relative;
}

.react-contexify__item:focus {
  outline: 0;
}

.react-contexify__item:not(.react-contexify__item--disabled):hover > .react-contexify__item__content, .react-contexify__item:not(.react-contexify__item--disabled):focus > .react-contexify__item__content {
  color: #fff;
  background-color: #4393e6;
}

.react-contexify__item:not(.react-contexify__item--disabled):hover > .react-contexify__submenu {
  pointer-events: initial;
  opacity: 1;
}

.react-contexify__item--disabled {
  cursor: default;
  opacity: .5;
}

.react-contexify__item__content {
  white-space: nowrap;
  color: #333;
  align-items: center;
  padding: 6px 12px;
  display: flex;
  position: relative;
}

.react-contexify__theme--dark, .react-contexify__theme--dark .react-contexify__submenu {
  background-color: #282828fa;
}

.react-contexify__theme--dark .react-contexify__separator {
  background-color: #eee;
}

.react-contexify__theme--dark .react-contexify__item__content {
  color: #fff;
}

.react-contexify__theme--light .react-contexify__separator {
  background-color: #eee;
}

.react-contexify__theme--light .react-contexify__submenu--is-open, .react-contexify__theme--light .react-contexify__submenu--is-open > .react-contexify__item__content, .react-contexify__theme--light .react-contexify__item:not(.react-contexify__item--disabled):hover > .react-contexify__item__content, .react-contexify__theme--light .react-contexify__item:not(.react-contexify__item--disabled):focus > .react-contexify__item__content {
  color: #4393e6;
  background-color: #e0eefd;
}

.react-contexify__theme--light .react-contexify__item__content {
  color: #666;
}

@keyframes react-contexify__scaleIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 1;
  }
}

@keyframes react-contexify__scaleOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
}

.react-contexify__will-enter--scale {
  transform-origin: 0 0;
  animation: .3s react-contexify__scaleIn;
}

.react-contexify__will-leave--scale {
  transform-origin: 0 0;
  animation: .3s react-contexify__scaleOut;
}

@keyframes react-contexify__fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes react-contexify__fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(10px);
  }
}

.react-contexify__will-enter--fade {
  animation: .3s react-contexify__fadeIn;
}

.react-contexify__will-leave--fade {
  animation: .3s react-contexify__fadeOut;
}

@keyframes react-contexify__flipInX {
  from {
    transform: perspective(800px)rotate3d(1, 0, 0, 45deg);
  }

  to {
    transform: perspective(800px);
  }
}

@keyframes react-contexify__flipOutX {
  from {
    transform: perspective(800px);
  }

  to {
    opacity: 0;
    transform: perspective(800px)rotate3d(1, 0, 0, 45deg);
  }
}

.react-contexify__will-enter--flip {
  transform-origin: top;
  animation: .3s react-contexify__flipInX;
  backface-visibility: visible !important;
}

.react-contexify__will-leave--flip {
  transform-origin: top;
  animation: .3s react-contexify__flipOutX;
  backface-visibility: visible !important;
}

@keyframes swing-in-top-fwd {
  0% {
    transform-origin: top;
    opacity: 0;
    transform: rotateX(-100deg);
  }

  100% {
    transform-origin: top;
    opacity: 1;
    transform: rotateX(0);
  }
}

@keyframes react-contexify__slideIn {
  from {
    opacity: 0;
    transform: scale3d(1, .3, 1);
  }

  to {
    opacity: 1;
  }
}

@keyframes react-contexify__slideOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale3d(1, .3, 1);
  }
}

.react-contexify__will-enter--slide {
  transform-origin: top;
  animation: .3s react-contexify__slideIn;
}

.react-contexify__will-leave--slide {
  transform-origin: top;
  animation: .3s react-contexify__slideOut;
}

.fullWidthButton {
  appearance: none;
  height: 26px;
  width: 100%;
  background: #f9fafa;
  border: 1px solid #f9fafa;
  border-radius: 2px;
  margin: 4px 0 0;
  padding: 0 4px;
  font-size: 12px;
}

.fullWidthButton:hover {
  background: #00000026;
  border: 1px solid #00000026;
}

.noOutlineText {
  font-size: 12px;
}

.sortableItem {
  height: 26px;
  background: #f9fafa;
  border: 1px solid #f9fafa;
  flex-direction: row;
  align-items: center;
  padding: 0 4px;
  font-size: 12px;
  display: flex;
  position: relative;
}

.sortableItem:hover {
  background: #00000026;
  border: 1px solid #00000026;
}

.sortableItem > .label {
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  overflow: hidden;
}

.sortableItem .controls {
  color: #666;
  visibility: hidden;
  z-index: 1;
  flex-direction: column;
  padding-left: 4px;
  display: flex;
}

.sortableItem .metadata {
  color: #666;
  position: absolute;
  right: 4px;
}

.sortableItem .metadata span {
  font-weight: bold;
}

.sortableItem svg {
  cursor: pointer;
}

.sortableItem:hover .controls {
  visibility: visible;
}

.sortableItem:hover .metadata {
  visibility: hidden;
}

.sortableItem .inputWrapper {
  flex-direction: row;
  align-content: stretch;
  display: flex;
}

.inputWrapper input {
  width: 100%;
  flex: 1;
}

.nestable-item, .nestable-item-copy, .nestable-item .nestable-list, .nestable-item-copy .nestable-list {
  margin: 4px 0 0 !important;
}

.nestable {
  position: relative;
}

.nestable .nestable-list {
  margin: 0;
  padding: 0 0 0 40px;
  list-style-type: none;
}

.nestable > .nestable-list {
  padding: 0;
}

.nestable-item, .nestable-item-copy {
  margin: 10px 0 0;
}

.nestable-item:first-child, .nestable-item-copy:first-child {
  margin-top: 0;
}

.nestable-item .nestable-list, .nestable-item-copy .nestable-list {
  margin-top: 10px;
}

.nestable-item {
  position: relative;
}

.nestable-item.is-dragging .nestable-list {
  pointer-events: none;
}

.nestable-item.is-dragging * {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity= 0);
}

.nestable-item.is-dragging:before {
  content: " ";
  background: #87ceeb;
  border: 1px dashed #4682b4;
  border-radius: 5px;
  position: absolute;
  inset: 0;
}

.nestable-item-icon {
  cursor: pointer;
  margin-right: 5px;
}

.nestable-drag-layer {
  z-index: 100;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
}

.nestable-drag-layer > .nestable-list {
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.nestable-icon {
  width: 20px;
  height: 20px;
  background-color: #0000;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;
}

.nestable-icon:before {
  content: "-";
  width: 0;
  height: 0;
  display: inline-block;
  overflow: hidden;
}

.icon-plus-gray {
  width: 20px;
  height: 20px;
  background-image: url("icon-plus-gray.bb5bea73.svg");
}

.icon-minus-gray {
  width: 20px;
  height: 20px;
  background-image: url("icon-minus-gray.a3287c2b.svg");
}

.collapseIcon {
  margin-top: 5px;
  margin-right: 2px;
}

.nestable-item-handler {
  height: 26px;
  position: absolute;
  left: 0;
  right: 0;
}

.nestable-item--with-children > div .nestable-item-handler {
  left: 20px;
}

/*# sourceMappingURL=index.bc39566f.css.map */
